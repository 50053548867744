import axios from "axios";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Connect = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const hmac = queryParams.get("hmac");
  const host = queryParams.get("host");
  const shop = queryParams.get("shop");

  const shopName = shop.split(".")[0];

  useEffect(() => {
    try {
      const sendData = async () => {
        await axios.get(
          `${process.env.REACT_APP_API_URL}/connect?code=${encodeURIComponent(
            code
          )}&hmac=${encodeURIComponent(hmac)}&host=${encodeURIComponent(
            host
          )}&shop=${encodeURIComponent(shop)}`
        ).catch((err) => {
          console.log("connect error: ", err)
          toast.error("Something went wrong. Please try again later")
        });
        window.location.href = `https://admin.shopify.com/store/${shopName}/apps/${process.env.REACT_APP_PRODUCT_NAME}`;
      };

      sendData();
    } catch (error) {
      throw new Error("Unable to store the data");
    }
  }, [code, hmac, host, shop, shopName]);
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ fontSize: "50px" }}
    >
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          containerId="containerA"
        />
        <ToastContainer />
      </div>
      Please wait...
    </div>
  );
};

export default Connect;
